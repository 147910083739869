// https://stackoverflow.com/questions/68418119/how-to-add-custom-colors-to-bulma
// https://bulma.io/documentation/customize/variables/
$orange: #f9822d;
$purple: #bd0f7f;
$custom-colors: ("orange": ($orange, white), "purple": ($purple, white));

$table-row-hover-background-color: #fafafa;
$table-cell-border: 1px solid #f5f5f5;
$table-head-cell-border-width: 0 0 1px;

$tabs-link-color: #762282;
$tabs-link-hover-color: #bd0f7f;
$tabs-link-active-color: #bd0f7f;

$tabs-border-bottom-width: 2px;
$tabs-border-bottom-color: #f5f5f5;
$tabs-link-active-border-bottom-color: #762282;
$tabs-link-active-color: #762282;
$tabs-link-hover-border-bottom-color: #f5f5f5;

$pagination-current-background-color: #bd0f7f;
$pagination-current-border-color: #bd0f7f;

$dropdown-content-offset: 0;

.dropdown-item {
  font-size: 1.0rem !important;
  line-height: 1 !important;
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
}

.dropdown-item:not(.is-disabled):hover {
  background: #fafafa;
}