span.value-label {
  color: gray;
  text-transform: uppercase;
  font-size: smaller;
  display: block;
}

span.status-circle {
  border-radius: 100%;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-top: 0.1em;
}

span.is-disabled {
  cursor: not-allowed !important;
  color: gainsboro !important;
}
