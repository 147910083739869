div.module-view {
    td {
        padding-left: 0px !important;
        padding-right: 0px !important;
        vertical-align: middle  !important;

        div.select {
            height: 1.8em !important;
        }

        span.snippet-name,
        span.snippet-src {
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &.snippet-name {
                max-width: 200px;
            }
            &.snippet-src {
                max-width: 250px;
            }
        }

        button {
            margin-top: 0.035rem !important;
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            height: 2.0rem !important;
            border: none !important;
        }

        select {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            height: 1.85rem !important;
            border: none !important;
            margin-top: -0.02rem;
        }
    }

    th {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    tbody  {

        tr:hover {
            background-color: #fafafa;
            color: #BD0F7F;
        }
    }
}