.link {
  color: #762282;
  cursor: pointer;
  text-decoration: none;

  &.disabled {
    color: gray;
    cursor: not-allowed;
  }

  &:not(.disabled):hover {
    color: #bd0f7f;
  }
}

.link.selected {
  padding-bottom: 0.22rem;
  border-bottom: 0.25rem solid #762282;
}
