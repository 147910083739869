div.app.firefox.windows {
    --calendar-span-margin-bottom: 0.075em;
}

div.preset {
    width: 100%;
    cursor: pointer;
}

div.preset:hover {
    color: purple;
}

.calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.day {
    margin: 5px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;

    span {
        display: block;
        margin-bottom: var(--calendar-span-margin-bottom, 0);
    }
}

.day.highlight:before {
    content: " ";
    position: absolute;
    background: #bd0f7f;
    top: 0;
    left: -5px;
    right: -5px;
    bottom: 0;
    opacity: 0.1;
    z-index: -1;
}

.day.highlight.start:before {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    opacity: 0.25;
}

.day.highlight.end:before {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    opacity: 0.25;
}

.day.highlight.selected {
    color: white;
}

.day.highlight.selected:before {
    opacity: 1;
}

.weeks {
    color: #9e9e9e;
    cursor: default;
}

.inactive {
    visibility: hidden;
    color: #cecece;
    cursor: not-allowed;
}

.today {
    font-weight: bold;
}