table {
  &.is-hoverable tr:hover {
      span {
        color: $purple;
      }
  }

  &:not(.is-bordered) {
    td:first-child,
    th:first-child {
      padding-left: 0;
    }

    td:last-child,
    th:last-child {
      padding-right: 0;
    }
  }
}