div.inline-hint {
    display: inline;
    position: relative;
    cursor: pointer;
    svg {
        margin-bottom: 0.05em;
    }
    span {
        display: none;
        font-size: 0.9rem;
        white-space: nowrap;
        margin-left: 1rem;
        padding: 0.1rem 0.3rem;
        background: lightgoldenrodyellow;
        border: 1px solid darkgray;
    }

    &:hover {
        span {
            top: 0;
            left: 100%;
            position: absolute;
            display: block;
        }
    }
}